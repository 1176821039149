import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import snakecaseKeys from 'snakecase-keys';
import useConsole from '../../../shared/hooks/useConsole.tsx';
import { useModal } from '../../../shared/hooks/useModal.tsx';
import { DocumentDetails } from '../../../shared/models/document.ts';
import { createDocumentCopy } from '../../../shared/store/documentSlice.ts';
import { patchTopology } from '../../../shared/store/inboxSlice.ts';
import { useDispatch } from '../../../shared/store/store.ts';
import s from '../../../shared/styles/component/document/document-mutate.module.scss';
import { ReactComponent as CrossIcon } from '../../../shared/assets/svg/cross-icon.svg';
import { ReactComponent as CheckmarkIcon } from '../../../shared/assets/svg/checkmark-icon.svg';

import Modal from '../../shared/modal/Modal.tsx';

interface Props {
  setCanSwitchToCopy: (value: boolean) => void;
  currentDocument: DocumentDetails;
  inboxId: string;
  currentThumbs?: any[];
}

const DocumentMutateModal: React.FC<Props> = ({
  currentDocument,
  currentThumbs,
  inboxId,
  setCanSwitchToCopy,
}) => {
  const { closeModal } = useModal();

  const dispatch = useDispatch();
  const [partsToCopy, setPartsToCopy] = useState(null);
  useEffect(() => {
    if (partsToCopy == null) {
      setPartsToCopy(
        currentDocument.topology.parts.map((_, i) => {
          if (!_.archived) return i;
        })
      );
    }
  }, [partsToCopy, currentDocument]);

  const handleSubmit = () => {
    dispatch(createDocumentCopy(currentDocument.id, inboxId)).then((res) => {
      console.log(res.data.id);
      if (res.data.id) {
        // Create correct topology
        const newTopology = {
          parts: {},
          // ...currentDocument.topology,
        };
        currentDocument.topology.parts.forEach((p, i) => {
          newTopology.parts[p.id] = { ...p, archived: !partsToCopy?.includes(i) };
          delete newTopology.parts[p.id].id;
        }),
          dispatch(patchTopology(res.data.id, inboxId, { topology: snakecaseKeys(newTopology) }, true)).then(
            () => {
              // dispatch(documentSlice.actions.setSelectedCopyId(res.data.id));
              closeModal();
            }
          );
      }
    });
    setCanSwitchToCopy(true);
  };
  useConsole(partsToCopy);
  return (
    <Modal>
      <div className={s.modal} style={{ maxWidth: Math.min(currentDocument.topology.parts.length, 5) * 260 }}>
        <div className={s.header}>
          <h2 className={s.title}> Document Splitting</h2>
          <CrossIcon onClick={closeModal} className={s.close} />
        </div>
        <div className={s.content}>
          <div className={s.sub_row}>
            <span className={s.description}>
              Indicate which parts of the document you want to include in your copy.
            </span>
          </div>
          <div className={s.page_grid}>
            {
              // return (
              // <div className={s.group}>

              currentDocument.topology.parts.map((part, pi) => {
                if (!partsToCopy) return null;
                const activePages = part.pages.filter((p) => !p.archived);
                return (
                  <>
                    <div
                      className={clsx(s.page)}
                      onClick={() => {
                        if (partsToCopy?.includes(pi)) {
                          setPartsToCopy(partsToCopy.filter((p) => p !== pi));
                        } else {
                          setPartsToCopy([...partsToCopy, pi]);
                        }
                      }}
                      style={{ flex: `0 0 calc(${100 / 5}% - 25px)` }}
                    >
                      <div className={clsx(s.page_wrapper)}>
                        {activePages.map((page, pageIndex) => {
                          if (pageIndex > 4) return null;
                          const img = currentThumbs?.find((t) => t.pageNo === page.bundlePageNo);

                          return (
                            <img
                              className={clsx(s.page_image, {
                                [s.page_image__faded]: !partsToCopy.includes(pi),
                              })}
                              style={{
                                top: 15 + pageIndex * 5,
                                left: 0 + pageIndex * 5,
                                zIndex: 100 - pageIndex,
                              }}
                              src={img.imageString}
                              alt="thumbnail"
                            />
                          );
                        })}
                      </div>

                      <div
                        className={clsx(
                          s.page_number,
                          { [s.page_number_add]: partsToCopy?.includes(pi) },
                          { [s.page_number_discard]: !partsToCopy?.includes(pi) }
                        )}
                      >
                        {partsToCopy?.includes(pi) && <CheckmarkIcon />}
                        {!partsToCopy?.includes(pi) && <CrossIcon style={{ color: 'white' }} />}P
                        {activePages[0].bundlePageNo}
                        {activePages.length > 1 && ' - ' + activePages[activePages.length - 1].bundlePageNo}
                      </div>
                    </div>
                  </>
                );
              })
            }
          </div>

          <div className={s.footer}>
            <button
              onClick={handleSubmit}
              disabled={partsToCopy?.length === 0}
              className={clsx(s.button, s.button__alt)}
            >
              Kopieeren
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DocumentMutateModal;
